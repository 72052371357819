// 首页
import homeNoticeIcon from './images/home/notice_icon.png'
import homeBalanceBgImg from './images/home/balance_bg.png'
import homeDealIcon from './images/home/deal_icon.png'
// import homeSellIcon from './images/home/sell_icon.png'
import homeAmountIcon from './images/home/amount_icon.png'
import homeAmountIcon1 from './images/home/amount_icon1.png'
import homeAmountIcon2 from './images/home/amount_icon2.png'
import homePrevIcon from './images/home/prev_icon.png'
import homeNextIcon from './images/home/next_icon.png'
import homeCloseIcon from './images/home/close_icon.png'
import homeDoubleRightArrowIcon from './images/home/double_right_arrow_icon.png'
import homeBitRechargeIcon from './images/home/bit_recharge_icon.svg'
import homeBitWithdrawIcon from './images/home/bit_withdraw_icon.svg'

// 交易大厅
import tradingHallBalanceBgImg from './images/tradingHall/balance_bg.png'
import tradingHallServiceIcon from './images/tradingHall/white_service_icon.png'

// 个人中心
import myRadianBgImg from './images/my/radian_bg.png'
import myUnCertificationIcon from './images/my/un_certification_icon.png'
import myCertificationIcon from './images/my/certification_icon.png'
import myHelpIcon from './images/my/help_icon.png'
import myAboutIcon from './images/my/about_icon.png'
import myIcon1 from './images/my/icon1.png'
import myIcon2 from './images/my/icon2.png'
import myIcon3 from './images/my/icon3.png'
import myIcon4 from './images/my/icon4.png'
import myIcon5 from './images/my/icon5.png'
import myIcon6 from './images/my/icon6.png'
import myIcon7 from './images/my/icon7.png'

import mySolidDownIcon from './images/my/solid_down_icon.png'
import myIdentityCardBgImg from './images/my/identity_card_bg.png'
import myIdentityCardBgImg1 from './images/my/identity_card_bg1.png'
import myUploadBtnImg from './images/my/upload_btn.png'
import myRecognitionImg from './images/my/recognition_img.png'
import myAvatarSelected from './images/my/avatarSelected.png'

// 收付款
import paymentDoublArrowIcon from './images/payment/double_arrow_icon.png'
import paymentStatusBgImg from './images/payment/status_bg.png'
import paymentAddIcon from './images/payment/add.png'

/**
 *
 * 新的图
 *
 * */

// 公用
import commonHot from './images/common/hot_icon.png'
import commonScan from './images/common/scan_icon.png'
import commonServiceIcon from './images/common/service_icon.png'
import commonCalendarIcon from './images/common/calendar_icon.png'
import commonArrowDownIcon from './images/common/arrow_down_icon.png'
import commonCopyIcon from './images/common/copy_icon.png'
import commonPaymentIcon1 from './images/common/payment_icon1.png'
import commonPaymentIcon2 from './images/common/payment_icon2.png'
import commonPaymentIcon3 from './images/common/payment_icon3.png'
import commonCloseIcon from './images/common/closeIcon.png'
import commonColorArrowDownIcon from './images/common/arrow_down_icon1.png'
import commonTransIcon from './images/common/trans_icon.png'
import commonTansIcon2 from './images/common/tans_icon2.png'
import commonEyeClosed from './images/common/eyeClosed.png'
import commonEyeOpened from './images/common/eyeOpened.png'
import commonCsYesIcon from './images/common/service_yes_icon.png'
import commonEmptyImg from './images/common/empty_img.png'
import commonCodeBgImg from './images/common/code_bg.png'
import commonBitIcon from './images/common/icon_btc_defult.svg'
import maintenanceBg from './images/common/maintenanceBg.png'

// 首页
import homeMsgIcon from './images/home/msg_icon.png'
import homeSellIcon from './images/home/sellIcon.png'
import homeBuyIcon from './images/home/buyIcon.png'
import homeMenu from './images/home/menuIcon.png'
import commonPaymentIcon21 from './images/common/alipayIcon.png'
import commonPaymentIcon22 from './images/common/wechatIcon.png'
import commonPaymentIcon23 from './images/common/bankIcon.png'

import commonPaymentIconGray21 from './images/common/alipayIconGray.png'
import commonPaymentIconGray22 from './images/common/wechatIconGray.png'
import commonPaymentIconGray23 from './images/common/bankIconGray.png'

import commonPaymentIconVn1 from './images/common/zaloVn.png'
import commonPaymentIconVn2 from './images/common/momoVn.png'
import commonPaymentIconVn3 from './images/common/bankVn.png'

import commonPaymentIconGrayVn1 from './images/common/zaloVnGray.png'
import commonPaymentIconGrayVn2 from './images/common/momoVnGray.png'
import commonPaymentIconGrayVn3 from './images/common/bankVnGray.png'

import homePopBgImg from './images/home/pop_bg.png'
import homePopBgImgVn from './images/home/pop_bg_vn.png'

import homeHelpLeftImg from './images/home/help_left_back.png'
import homeHelpRightImg from './images/home/help_right_back.png'
import homeHelpLeftLabelText from './images/home/tabbar_active_label_left_icon.png'
import homeHelpRightLabelText from './images/home/tabbar_active_label_right_icon.png'
import homeHelpRightCurrentTab from './images/home/help_right_active_tab.png'
import homeHelpLeftCurrentTab from './images/home/help_left_active_tab.png'


//  底部tabBar
import footerHome from './images/tabBar/home_icon.png'
import footerHomeActive from './images/tabBar/home_active_icon.png'
import footerTrade from './images/tabBar/trade_icon.png'
import footerTradeActive from './images/tabBar/trade_active_icon.png'
import footerService from './images/tabBar/service_icon.png'
import footerServiceActive from './images/tabBar/service_active_icon.png'
import footerMy from './images/tabBar/my_icon.png'
import footerMyActive from './images/tabBar/my_active_icon.png'

// 侧边栏菜单
import aboutUs from './images/sideBar/aboutUs.png'
import agencyCommission from './images/sideBar/agencyCommission.png'
import fundRecord from './images/sideBar/fundRecord.png'
import helpCenter from './images/sideBar/helpCenter.png'
import logout from './images/sideBar/logout.png'
import onlineService from './images/sideBar/onlineService.png'
import orderList from './images/sideBar/orderList.png'
import payment from './images/sideBar/payment.png'
import personalReport from './images/sideBar/personalReport.png'
import arrowRight from './images/sideBar/arrowRight.png'
import wallet from './images/sideBar/wallet.png'
import modifyLoginPassword from './images/sideBar/modifyLoginPassword.png'
import modifyPaymentPassword from './images/sideBar/modifyPaymentPassword.png'
import menuLeft from './images/sideBar/menuLeft.png'
import certification from './images/sideBar/certification.png'
import scan from './images/sideBar/scan.png'

// 登录注册
import loginRegCloseIcon from './images/loginReg/closeIcon.png'
import loginRegCsIcon from './images/loginReg/csIcon.png'

// 交易流程详情页面
import orderDetailsProcessIcon from './images/order/process_icon.png'
import orderDetailsProcessActiveIcon from './images/order/process_active_icon.png'
import orderBitFailIcon from './images/order/icon_tips.svg'
import orderDetailQrCodeBgImg from './images/order/qrcode_back.png'

// 交易大厅
import tradingHallAmountIcon from './images/tradingHall/amountIcon.png'

//  个人中心
import myAmountBgImg from './images/my/my_amount_back.png'
import myCopyIcon from './images/my/my_copy_icon.png'

//  快捷买币
import quickPayWallIcon from './images/payment/wallet_icon.png'

//  实名认证
import myIdentityCardBgImgVi from './images/my/vi_identity_card_bg.png'
import myIdentityCardBgImg1Vi from './images/my/vi_identity_card_bg1.png'

export default {
    // 公用
    commonCopyIcon,
    commonTransIcon,
    commonArrowDownIcon,
    commonColorArrowDownIcon,
    commonEmptyImg,
    commonCalendarIcon,
    commonPaymentIcon1,
    commonPaymentIcon2,
    commonPaymentIcon3,
    commonPaymentIconVn1,
    commonPaymentIconVn2,
    commonPaymentIconVn3,
    commonPaymentIconGrayVn1,
    commonPaymentIconGrayVn2,
    commonPaymentIconGrayVn3,
    commonCodeBgImg,
    commonCsYesIcon,
    commonBitIcon,
    maintenanceBg,
    // 首页
    homeMsgIcon,
    homeNoticeIcon,
    homeBalanceBgImg,
    homeDealIcon,
    homeAmountIcon,
    homeAmountIcon1,
    homeAmountIcon2,
    homePopBgImg,
    homePopBgImgVn,
    homePrevIcon,
    homeNextIcon,
    homeCloseIcon,
    homeDoubleRightArrowIcon,
    homeBitRechargeIcon,
    homeBitWithdrawIcon,
    // 交易大厅
    tradingHallBalanceBgImg,
    tradingHallServiceIcon,
    tradingHallAmountIcon,
    // 个人中心
    myRadianBgImg,
    myUnCertificationIcon,
    myCertificationIcon,
    myHelpIcon,
    myAboutIcon,
    myIcon1,
    myIcon2,
    myIcon3,
    myIcon4,
    myIcon5,
    myIcon6,
    myIcon7,
    mySolidDownIcon,
    myIdentityCardBgImg,
    myIdentityCardBgImg1,
    myUploadBtnImg,
    myRecognitionImg,
    myAvatarSelected,
    // 收付款
    paymentDoublArrowIcon,
    paymentStatusBgImg,
    paymentAddIcon,
    // 交易流程详情页面
    orderDetailsProcessIcon,
    orderDetailsProcessActiveIcon,
    orderBitFailIcon,
    orderDetailQrCodeBgImg,
    /**
     * 新的图
     * */
    // 公共
    commonHot,
    commonScan,
    commonServiceIcon,
    commonPaymentIcon21,
    commonPaymentIcon22,
    commonPaymentIcon23,
    commonPaymentIconGray21,
    commonPaymentIconGray22,
    commonPaymentIconGray23,
    commonCloseIcon,
    commonTansIcon2,
    commonEyeClosed,
    commonEyeOpened,
    // sidebar
    aboutUs,
    agencyCommission,
    fundRecord,
    helpCenter,
    logout,
    onlineService,
    orderList,
    payment,
    personalReport,
    arrowRight,
    wallet,
    modifyLoginPassword,
    modifyPaymentPassword,
    menuLeft,
    certification,
    scan,
    // 首页
    homeSellIcon,
    homeBuyIcon,
    homeMenu,
    homeHelpLeftImg,
    homeHelpRightImg,
    homeHelpLeftLabelText,
    homeHelpRightLabelText,
    homeHelpLeftCurrentTab,
    homeHelpRightCurrentTab,
    // 登录注册
    loginRegCloseIcon,
    loginRegCsIcon,
    //  底部tabbar
    footerHome,
    footerHomeActive,
    footerTrade,
    footerTradeActive,
    footerService,
    footerServiceActive,
    footerMy,
    footerMyActive,
    //  个人中心
    myAmountBgImg,
    myCopyIcon,
    //  快捷买币
    quickPayWallIcon,
    //  实名认证
    myIdentityCardBgImgVi,
    myIdentityCardBgImg1Vi,
}
